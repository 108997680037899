import React from 'react';
import { useParams } from 'react-router-dom';
import { useContract } from '../api/getContract';
import { getServiceFeePercentage } from 'utils/getServiceFeePercentage';

export const ServiceFeePercentage = () => {
  const { id } = useParams();

  const contractQuery = useContract({ id });

  if (contractQuery.isLoading) {
    return <></>;
  }

  if (!contractQuery.data) return null;

  const service_fee_percentage = getServiceFeePercentage(
    contractQuery.data?.loanAmount,
    contractQuery.data?.product?.service_fees
  );

  return <span className="font-bold">{service_fee_percentage || 6.5}%</span>;
};
