import React, { useState } from 'react';
import * as z from 'zod';
import { Button, Spinner } from 'components/Elements';
import { Form } from 'components/Form';
import { ReactSelectField } from 'components/Pigeon';
import { useMyAssets } from 'features/assets';
import storage from 'utils/storage';

const schema = z.object({
  collateral: z.array(z.string({
    required_error: "collateral is required",
    invalid_type_error: "collateral must be a string",
  })),
});

export const Collateral = ({ nextStep, prevStep }) => {
  const myAssetsQuery = useMyAssets();

  const [collaterals, setCollaterals] = useState();

  if (myAssetsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
      <Form
        id="create-loan"
        onSubmit={() => {
          // console.log('collaterals', collaterals)
          storage.setFormValues({
            collateral: collaterals,
          });
          nextStep();
        }}
        schema={schema}>
        {({ register, formState, watch, setValue }) => (
          <div className="px-12 md:px-24 pt-12 m">
            <div className="text-lg mb-6">Select your collateral Assets</div>
              <ReactSelectField
                label="Select from your assets"
                registration={register('collateral')}
                options={
                  myAssetsQuery.data?.map((asset) => ({
                    label: asset.title,
                    value: asset._id,
                  })) || []
                }
                editorContent={watch('collateral')}
                setValue={()=>{}}
                name="collateral"
                isMulti={true}
                defaultValue={watch('collateral')}
                error={formState.errors['collateral']}
                onChange={(e) => {
                  
                  formState.errors['collateral'] = ''
                  setCollaterals(e.map((item) => item.value));
                  setValue('collateral', e.map((item) => item.value))
                  // console.log('collateral', collaterals)
                }}
              />
              <div className="mx-0 md:mx-32 flex flex-col space-y-3 mt-4">
                <div className="flex flex-row items-center space-x-2">
                  <Button variant="outline" onClick={() => prevStep()} className="w-full">
                    Previous
                  </Button>
                  <Button variant="dark" type="submit" className="w-full">
                    Next
                  </Button>
                </div>
              </div>
           
          </div>
        )}
      </Form>
  );
};
