import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Accordion, Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { DaysInArrearsInfo } from '../components/Info/DaysInArrearsInfo';
import { useMyLoan } from '../api/getMyLoan';
import { getNextPayment } from 'utils/getNextPayment';
import { formatDate } from 'utils/format';
import { CreateRepayment } from '../components/CreateRepayment';
import { MyLoanLog } from '../components/MyLoanLog';
import { ConfirmBorrowerSignature } from 'features/contracts';

export const MyLoan = () => {
  const routes = [
    {
      name: 'My Loan',
      path: '/app/my-loan',
    },
  ];
  const navigate = useNavigate();

  const myLoanQuery = useMyLoan();

  if (!myLoanQuery.data) return null;

  const nextPayment = getNextPayment(myLoanQuery?.data?.payments);

  return (
    <ContentLayout
      title="My Loan"
      end={
        <div className="mt-4 md:mt-0 flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
          {myLoanQuery.data?.status === 'ACTIVE' && (
            <CreateRepayment
              amount={nextPayment?.installmentAmount || myLoanQuery.data?.monthyPayment}
            />
          )}
          {/* {myLoanQuery.data?.status === 'ACTIVE' && (
            <Button
              className="w-full whitespace-nowrap"
              variant="outline"
              size="sm"
              onClick={() => navigate('/app/my-loan/report')}
            >
              Download Report
            </Button>
          )} */}
          {myLoanQuery.data.status === 'CC_APPROVED' && (
            <Button
              className="w-full whitespace-nowrap"
              size="sm"
              onClick={() => navigate('/app/my-loan/view-offers')}
            >
              View Offers
            </Button>
          )}
        </div>
      }
    >
      <div className="flex flex-col md:flex-row items-center justify-between mb-3">
        <Breadcrumbs routes={routes} />
      </div>

      {myLoanQuery.data?.status === 'ACTIVE' && (
        <div
          className={`p-4 bg-gradient-to-r from-green-100 to-green-200 border border-2 border-green-400 rounded-lg shadow-lg text-start`}
        >
          <div className="mb-1 flex flex-row items-center space-x-2 text-gray-800">
            <div className="rounded-full bg-white p-1 px-2 uppercase font-bold text-green-500">
              Active Loan
            </div>
          </div>
          <p className="text-sm mb-2 text-green-800">
            Your loan is active and ready to fulfill your dreams.
          </p>
        </div>
      )}
      {myLoanQuery.data?.status === 'NEW' && (
        <div
          className={`mt-4 p-6 bg-gradient-to-r from-primary-200 to-primary-500 text-white border border-gray-300 rounded-lg shadow-lg text-center transition duration-1000 ease-in-out animate-pulse`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">New</div>
          </div>
          <p className="text-md mb-2">
            Your loan request has been received and is currently being reviewed by our team.
          </p>
        </div>
      )}
      {myLoanQuery.data?.status === 'CC_APPROVED' && (
        <div
          className={`p-6 bg-gradient-to-r from-orange-400 to-orange-500 text-white  rounded-lg shadow-lg text-center transition duration-1000 ease-in-out animate-pulse`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">
              Approved by Credit Committee
            </div>
          </div>
          <p className="text-md mb-2 text-start">
            Your loan request has been approved by EFL (Enabling Finance Limited) credit committee.
            Expect an offer from a lender soon.
          </p>
        </div>
      )}
      {myLoanQuery.data?.status === 'IN_REVIEW' && (
        <div
          className={`p-6 bg-gradient-to-r from-orange-400 to-orange-500 text-white  rounded-lg shadow-lg text-center`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">In Review</div>
          </div>
          <p className="text-md mb-2">
            Your loan request is currently under review by EFL (Enabling Finance Limited). You'll be
            notified once it's been approved.
          </p>
        </div>
      )}
      {myLoanQuery.data?.status === 'EFL_APPROVED' && (
        <div
          className={`p-6 bg-gradient-to-r from-orange-400 to-orange-500 text-white rounded-lg shadow-lg text-center`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">Approved by EFL</div>
          </div>
          <p className="text-md mb-2">
            Your loan request has been accepted by EFL (Enabling Finance Limited) and you'll be
            notified once it's been approved.
          </p>
        </div>
      )}

      {myLoanQuery.data?.status === 'OFFER_ACCEPTED' && (
        <div
          className={`p-6 bg-gradient-to-r from-orange-400 to-orange-500 text-white  rounded-lg shadow-lg text-start`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">
              Lender Offer Accepted
            </div>
          </div>
          {myLoanQuery.data?.contract &&
          !myLoanQuery.data?.contract?.borrower_signature?.confirmed_by_borrower ? (
            <>
              <p className="text-md mb-2">Please confirm your signature on the contract below.</p>
              <ConfirmBorrowerSignature id={myLoanQuery.data?.contract?._id} />
            </>
          ) : (
            <p className="text-md mb-2">
              You've confirmed your signature. Your funds will be released once your contract has
              been approved by EFL (Enabling Finance Limited).
            </p>
          )}
        </div>
      )}
      {myLoanQuery.data?.status === 'DUE_DELIGENCE' && (
        <div
          className={`p-6 bg-gradient-to-r from-orange-400 to-orange-500 text-white  rounded-lg shadow-lg text-start`}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <div className="">Current Status</div>
            <div className="rounded-full bg-white p-1 px-2 text-orange-500">Due Diligence</div>
          </div>
          <p className="text-md mb-2">
            Your loan is undergoing due diligence. Check your notifications for checklist.
          </p>
        </div>
      )}
      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="col-span-2 bg-white rounded-2xl shadow-md p-8 flex flex-row items-center justify-between">
            <div className="">
              {/* <div className="">Total Balance To Close As Per Contract</div>
              <div className="font-bold">12,000,000 UGX</div> */}
              <div className="">Principal</div>
              <div className="font-bold">
                UGX {myLoanQuery.data?.amount?.loanRequired?.toLocaleString()}
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <Button
                onClick={() => navigate('/app/my-loan/repayment-schedule')}
                variant="outline"
                size="sm"
              >
                Repayment Schedule
              </Button>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Loan Duration</div>
            <div className="font-bold">{myLoanQuery.data?.term} months</div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Days in arrears</div>
            <div className="font-bold">{myLoanQuery.data?.daysInAreas || 0} days</div>
            <div className="flex flex-row justify-end">
              <DaysInArrearsInfo />
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Arrears</div>
            <div className="font-bold">UGX {myLoanQuery.data?.arrears?.toLocaleString() || 0}</div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Recovery Status</div>
            <div className="font-bold">CLEAR</div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Next Payment Expected</div>
            <div className="font-bold">{formatDate(nextPayment?.dateExpected)}</div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8">
            <div className="">Upcoming Deposit</div>
            <div className="font-bold">
              {nextPayment?.installmentAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'UGX',
              })}
            </div>
          </div>
          <div className="relative bg-white rounded-2xl shadow-md p-8">
            <div className="absolute top-0 right-0 mt-2 mr-2 rounded-full p-1 px-2 bg-green-500 text-white">
              As Per Schedule
            </div>
            <div className="">Total Balance To Close</div>
            <div className="font-bold">UGX {myLoanQuery.data.totalPayable?.toLocaleString()}</div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Accordion title="Audit Log">
          <div className="">
            <MyLoanLog data={myLoanQuery.data?.comments} />
          </div>
        </Accordion>
      </div>
    </ContentLayout>
  );
};
