import { UPLOADS_URL } from 'config';
import React from 'react';

export const DueDiligenceComponentFiles = ({ dueDiligence }) => {
  if (!dueDiligence || !dueDiligence.component) {
    return <div>No due diligence data available.</div>;
  }

  return (
    <div className="due-diligence-component-files">
      {dueDiligence.component.map((component) => (
        <div key={component._id} className="component">
          <div className="images">
            {component.images.map((image, index) => (
              <img
                key={index}
                src={`${UPLOADS_URL}/documents/duediligence/${image}`}
                alt={`Component ${component.name} - ${index + 1}`}
                className="component-image"
              />
            ))}
          </div>
          <div className="attachments">
            {component.attachments.map((attachment, index) => (
              <a
                key={index}
                href={`${UPLOADS_URL}/documents/duediligence/${attachment}`}
                target="_blank"
                rel="noopener noreferrer"
                className="component-attachment"
              >
                Attachment {index + 1}
              </a>
            ))}
          </div>
          <div className="links">
            {component.links.map((link, index) => (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="component-link"
              >
                Link {index + 1}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
