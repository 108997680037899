import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { Button, Spinner } from 'components/Elements';
import {
  Form,
  InputField,
  LocationField,
  ReactNumberFormatInputField2,
  ReactSelectField,
  RegularTextAreaField,
  SelectField,
} from 'components/Form';
import { Head } from 'components/Head';
import Swal from 'sweetalert2';
import { useCreateAsset } from '../api/createAsset';
import { useNavigate } from 'react-router-dom';
import { useProducts } from 'features/products';
import { dataURItoBlob } from 'utils/dataURIToBlob';
import phoneBrands from '../data/phoneBrands.json';
import phoneModels from '../data/phoneModels.json';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { ReactSelectField2, ReactSelectFieldWithInfo } from 'components/Pigeon';
import storage from 'utils/storage';

const landSchema = z.object({
  title: z.string({
    required_error: "Title of Asset is required",
    invalid_type_error: "Title of Asset must be a string",
  }),
  product: z.string({
    required_error: "product is required",
    invalid_type_error: "product must be a string",
  }),
  land_location: z.string({
    required_error: "land location is required",
    invalid_type_error: "land location must be a string",
  }),
  type_of_title: z.string({
    required_error: "type of title is required",
    invalid_type_error: "type of title must be a string",
  }),
  size_of_land: z.string({
    required_error: "size of land is required",
    invalid_type_error: "size of land must be a string",
  }),
  whats_on_land: z.string({
    required_error: "whats on land is required",
    invalid_type_error: "whats on land must be a string",
  }),
  specify_others: z.string().optional(),
  description: z.string({
    required_error: "Asset description is required",
    invalid_type_error: "Asset description must be a string",
  }),
});

const landfileSchema = z.object({
  imageinput0: z.string({
    required_error: "Wide landscape view of land is required",
    invalid_type_error: "Wide landscape view of land must be an image",
  }),
  imageinput1: z.string({
    required_error: "Land with access road visible is required",
    invalid_type_error: "Land with access road visible must be an image",
  }),
  imageinput2: z.string({
    required_error: "Neighborhood view from land is required",
    invalid_type_error: "Neighborhood view from land must be an image",
  }),
  imageinput3: z.string({
    required_error: "Additional picture of neighbourhood is required",
    invalid_type_error: "Additional picture of neighbourhood must be an image",
  }),
  imageinput4: z.string({
    required_error: "Markstone/boundary point on land is required",
    invalid_type_error: "Markstone/boundary point on land must be an image",
  }),
  imageinput5: z.string({
    required_error: "Land title - Front page is required",
    invalid_type_error: "Land title - Front page must be an image",
  }),
  imageinput6: z.string({
    required_error: "Land title - Print page is required",
    invalid_type_error: "Land title - Print page must be an image",
  }),
  imageinput7: z.string({
    required_error: "Land title - Encumbrances is required",
    invalid_type_error: "Land title - Encumbrances must be an image",
  }),
  imageinput8: z.string({
    required_error: "Additional pages of land title is required",
    invalid_type_error: "Additional pages of land title must be an image",
  }),
  imageinput9: z.string({
    required_error: "Exterior landscape of building is required",
    invalid_type_error: "Exterior landscape of building must be an image",
  }),
  imageinput10: z.string({
    required_error: "Living room portrait is required",
    invalid_type_error: "Living room portrait must be an image",
  }),
  imageinput11: z.string({
    required_error: "Dining room portrait is required",
    invalid_type_error: "Dining room portrait must be an image",
  }),
  imageinput12: z.string({
    required_error: "Kitchen portrait is required",
    invalid_type_error: "Kitchen portrait must be an image",
  }),
  imageinput13: z.string({
    required_error: "Bedroom portrait is required",
    invalid_type_error: "Bedroom portrait must be an image",
  }),
});

const landValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: "purchase amount is required",
    invalid_type_error: "purchase amount must be a number",
  }),
  current_worth: z.number({
    required_error: "current worth is required",
    invalid_type_error: "current worth must be a number",
  }),
  purchase_date: z.string({
    required_error: "purchase date is required",
    invalid_type_error: "purchase date must be a number",
  }),
});
const vehiclesAssetDetailsSchema = z.object({
  title: z.string({
    required_error: "title is required",
    invalid_type_error: "title must be a string",
  }).min(1, { message: "title cannot be empty" }),
  product: z.string({
    required_error: "Product is required",
    invalid_type_error: "Product must be a string",
  }).min(1, { message: "Product cannot be empty" }),
  vehicle_sub_category: z.string({
    required_error: "vehicle category is required",
    invalid_type_error: "vehicle category must be a string",
  }).min(1, { message: "vehicle category cannot be empty" }),
  brand: z.string({
    required_error: "Brand is required",
    invalid_type_error: "Brand must be a string",
  }).min(1, { message: "Brand cannot be empty" }),
  specify_others: z.string({
    invalid_type_error: "Specify others must be a string",
  }).optional(),
  tracker: z.string({
    required_error: "tracker is required",
    invalid_type_error: "tracker must be a string",
  }).min(1, { message: "tracker cannot be empty" }),
  model: z.string({
    required_error: "model is required",
    invalid_type_error: "model must be a string",
  }).min(1, { message: "model cannot be empty" }),
  mileage: z.string({
    required_error: "mileage is required",
    invalid_type_error: "mileage must be a string",
  }).min(1, { message: "mileage cannot be empty" }),
  parking_status: z.string({
    required_error: "parking status is required",
    invalid_type_error: "parking status must be a string",
  }).min(1, { message: "parking status cannot be empty" }),
  year_of_manufacture: z.number({
    required_error: "year of manufacture is required",
    invalid_type_error: "year of manufacture must be a number",
  }).min(1, { message: "year of manufacture cannot be empty" }),
  description: z.number({
    required_error: "description is required",
    invalid_type_error: "description must be a number",
  }).min(1, { message: "description cannot be empty" }),
});
const vehiclesSchema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
});
const smartPhoneSchema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
});
const televisionSchema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
});
const computersSchema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
});
const otherElectronicsSchema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
});

export const CreateAsset = () => {
  const [category, setCategory] = useState('');
  const productsQuery = useProducts();
  const [assetDetails, setAssetDetails] = useState(false);
  const [pictures, setPictures] = useState(false);
  const [valuation, setValuation] = useState(false);
  const createAssetMutation = useCreateAsset();
  const [assetImages, setAssetImages] = useState(Array(8).fill(null));
  const [vehicleSubCategory, setVehicleSubCategory] = useState('private_vehicles');
  const [landLocation, setLandLocation] = useState('');
  const [whatsOnLand, setWhatsOnLand] = useState();
  const [brand, setBrand] = useState();
  const [product, setProduct] = useState();
  const [purchase_amount, setPurchaseAmount] = useState();
  const [current_worth, setCurrentWorth] = useState();
  const [nature_of_electronic, setNatureOfElectronic] = useState();
  const [landErr, setLandErr] = useState()
  const [vehicleAssetData, setvehicleAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    vehicle_sub_category: 'personal_vehicles',
    selectedVehicle_sub_category: 'personal_vehicles',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    tracker: '',
    selectedTracker: '',
    model: '',
    selectedModel: '',
    mileage: '',
    selectedMileage: '',
    parking_status: '',
    selectedParking_status: '',
    year_of_manufacture: '',
    description: '',
  })
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      // setAssetDetails(true);
      // localStorage.removeItem('assetCategory')
      const aw = localStorage.getItem('assetCategory')
      if (aw) {
        setCategory(aw);
        console.log('assetCategory', aw)
        if (aw === 'VEHICLES') {
          const aw1 = storage.getOtherValues('vehicleAssetData')
          console.log('vehicleAssetData', aw1)
          if (aw1.product) {
            setvehicleAssetData({ ...vehicleAssetData, product: aw1.product, selectedProduct: aw1.selectedProduct })
          }
        }
        const ad = localStorage.getItem('formStep')
        console.log('assetstep', ad)
        if (ad) {
          if (ad === 'assetDetails') {
            setAssetDetails(true);
          }
          if (ad === 'pictures') {
            setPictures(true);
          }
          if (ad === 'valuation') {
            setValuation(true);
          }
        } else {
          setAssetDetails(true);
        }
      }
    }
    getData()
  }, []);

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImages = [...assetImages];
        newImages[index] = reader.result;
        setAssetImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!productsQuery.data) return null;

  const routes = [
    {
      name: 'Asset Center',
      path: '/app/assets',
    },
    {
      name: 'Create Asset',
      path: '/app/assets/create',
    },
  ];

  return (
    <ContentLayout title="Create Asset">
      <Breadcrumbs routes={routes} />

      <div className="mt-8">
        <Head title="Create Asset" />
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-start">
          <div className="bg-white rounded-2xl shadow p-6 flex flex-row md:flex-col w-full">
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You will lose all your progress!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, close it!',
                    cancelButtonText: 'No, keep it',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setCategory('');
                      setPictures(false);
                      setValuation(false);
                      setAssetDetails(false);
                    }
                  });
                }}
                className={`${!category && `bg-gray-100`} text-primary-100 p-4 rounded-2xl`}>
                <span className="block md:hidden">1</span>
                <span className="hidden md:block">Choose Category</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setPictures(false);
                  setValuation(false);
                  setAssetDetails(true);
                }}
                className={`${assetDetails && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">2</span>
                <span className="hidden md:block">Asset Details</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(false);
                  setPictures(true);
                }}
                className={`${pictures && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}>
                <span className="block md:hidden">3</span>
                <span className="hidden md:block">Pictures</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(true);
                  setPictures(false);
                }}
                className={`${valuation && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}>
                <span className="block md:hidden">4</span>
                <span className="hidden md:block">Valuation</span>
              </button>
            </div>
          </div>
          <div className="md:col-span-3">
            {!category && (
              <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                <div className="mb-4">Choose Category</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <button
                    onClick={() => {
                      setCategory('REAL_ESTATE_WITH_LAND_TITLE');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'REAL_ESTATE_WITH_LAND_TITLE')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Real Estate With Land Title
                  </button>
                  <button
                    onClick={() => {
                      setCategory('VEHICLES');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'VEHICLES')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Vehicles
                  </button>
                  <button
                    onClick={() => {
                      setCategory('TELEVISION');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'TELEVISION')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Television
                  </button>
                  <button
                    onClick={() => {
                      setCategory('COMPUTERS');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'COMPUTERS')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Computer
                  </button>
                  <button
                    onClick={() => {
                      setCategory('SMART_PHONES');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'SMART_PHONES')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Smart Phones
                  </button>
                  <button
                    onClick={() => {
                      setCategory('OTHER_ELECTRONICS');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'OTHER_ELECTRONICS')
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Other Electronics
                  </button>
                </div>
              </div>
            )}
            {category === 'REAL_ESTATE_WITH_LAND_TITLE' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      // console.log('land submit', values)
                      localStorage.setItem('realestateAssetDetail', JSON.stringify(values))
                      localStorage.setItem('formStep', 'pictures')
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    schema={landSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for real estate</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(event) => {
                                formState.errors['title'] = ''
                                setValue('title', event.target.value)
                              }}
                            />
                            <ReactSelectField
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              setValue={setValue}
                              name="product"
                              defaultValue={product}
                              error={formState.errors['product']}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={(e) => {
                                formState.errors['product'] = ''
                                setProduct(e.value)
                              }}
                            />
                            <InputField
                              label="Land Location"
                              error={formState.errors['land_location']}
                              registration={register('land_location')}
                              name="land_location"
                              onChange={(event) => {
                                formState.errors['land_location'] = ''
                                setValue('land_location', event.target.value)
                              }}
                            />
                            <SelectField
                              label="Type of Title"
                              registration={register('type_of_title')}
                              options={[
                                { label: 'Private Mailo', value: 'PRIVATE_MAILO' },
                                { label: 'Freehold', value: 'FREEHOLD' },
                                { label: 'Leasehold', value: 'LEASEHOLD' },
                              ]}
                              error={formState.errors['type_of_title']}
                              name="type_of_title"
                              onChange={(event) => {
                                formState.errors['type_of_title'] = ''
                                setValue('type_of_title', event.value)
                              }}
                            />
                            <SelectField
                              label="Size of Land"
                              registration={register('size_of_land')}
                              options={[
                                { label: '50*100ft (12.5 decimals)', value: '12_5_DECIMALS' },
                                {
                                  label: '50*100ft to half acre (12.5 - 50 decimals)',
                                  value: '12_5_50_DECIMALS',
                                },
                                {
                                  label: 'Greater than half acre (51 decimals +)',
                                  value: '51_+_DECIMALS',
                                },
                              ]}
                              error={formState.errors['size_of_land']}
                              name="size_of_land"
                              onChange={(event) => {
                                formState.errors['size_of_land'] = ''
                                setValue('size_of_land', event.value)
                              }}
                            />
                            <SelectField
                              label="What's on the Land"
                              registration={register('whats_on_land')}
                              options={[
                                { label: 'Rentals', value: 'RENTALS' },
                                { label: 'Main House', value: 'MAIN_HOUSE' },
                                {
                                  label: 'Main House and Rentals',
                                  value: 'MAIN_HOUSE_AND_RENTALS',
                                },
                                { label: 'Apartments', value: 'APARTMENTS' },
                                { label: 'Hotel or Bar', value: 'HOTEL_BAR' },
                                { label: 'School or Market', value: 'SCHOOL_MARKET' },
                                { label: 'Empty Land', value: 'EMPTY_LAND' },
                                { label: 'Others', value: 'OTHERS' },
                              ]}
                              error={formState.errors['whats_on_land']}
                              name="whats_on_land"
                              onChange={(event) => {
                                formState.errors['whats_on_land'] = ''
                                setValue('whats_on_land', event.value)
                                setWhatsOnLand(event.value)
                              }}
                            />
                            {whatsOnLand === 'OTHERS' && (
                              <InputField
                                label="Specify Others"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="You can provide some extra details about the land that can help in the valuation"
                                name="specify_others"
                                onChange={(event) => {
                                  formState.errors['specify_others'] = ''
                                  setValue('specify_others', event.target.value)
                                }}
                              />
                            )}
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Describe your land in detail. Share any unique features, history, or potential uses. This information will help us understand your property better and tailor our services to meet your needs."
                              name="description"
                              onChange={(event) => {
                                formState.errors['description'] = ''
                                setValue('description', event.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            // onClick={() => {
                            //   setAssetDetails(false);
                            //   setPictures(true);
                            //   console.log('step 1 clicked, land info')
                            // }}
                            type="submit"
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      // console.log('land image submit', assetImages)
                      localStorage.setItem('realestateImageDetail', JSON.stringify(assetImages))
                      localStorage.setItem('formStep', 'valuation')
                      setPictures(false);
                      setValuation(true);
                    }}
                    schema={landfileSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images are clear and follow the guidelines on
                          what picture angles to take.
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {[...Array(15).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                name={`imageinput${index}`}
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = ''
                                  handleImageChange(index, event)
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />

                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div role="alert" aria-label={formState.errors[`imageinput${index}`].message} className="text-sm font-semibold text-red-500">
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {index === 0 && 'Wide landscape view of land'}
                                  {index === 1 && 'Land with access road visible'}
                                  {index === 2 && 'Neighborhood view from land'}
                                  {index === 3 && 'Additional picture of neighbourhood'}
                                  {index === 4 && 'Markstone/boundary point on land'}
                                  {index === 5 && 'Land title - Front page'}
                                  {index === 6 && 'Land title - Print page'}
                                  {index === 7 && 'Land title - Encumbrances'}
                                  {index === 8 && 'Additional pages of land title'}
                                  {index === 9 && 'Exterior landscape of building'}
                                  {index === 10 && 'Living room portrait'}
                                  {index === 11 && 'Dining room portrait'}
                                  {index === 12 && 'Kitchen portrait'}
                                  {index >= 13 && `Bedroom portrait ${index - 12}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}>
                            Back
                          </Button>
                          <Button type="submit">
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}

                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('')
                      let aw = localStorage.getItem('realestateAssetDetail')
                      let ad = localStorage.getItem('realestateImageDetail')
                      const as = localStorage.getItem('assetCategory')
                      if (!as) {
                        setLandErr('Select collateral category in step 1')
                        return
                      }
                      if (aw) {
                        aw = JSON.parse(aw)
                      } else {
                        setLandErr('Step 2 info missing')
                        return
                      }
                      if (ad) {
                        ad = JSON.parse(ad)
                      } else {
                        setLandErr('Step 3 info missing')
                        return
                      }
                      // console.log('submit data', aw, ad)
                      const bodyFormData = new FormData();
                      bodyFormData.append('title', aw.title);
                      bodyFormData.append('category', as);
                      bodyFormData.append('land_location', aw.land_location);
                      bodyFormData.append('product', aw.product);
                      bodyFormData.append('type_of_title', aw.type_of_title);
                      bodyFormData.append('size_of_land', aw.size_of_land);
                      bodyFormData.append('whats_on_land', aw.whats_on_land);
                      bodyFormData.append('specify_others', aw.specify_others);
                      bodyFormData.append('description', aw.description);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('current_worth', values.current_worth);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      // console.log('land submit', values)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        ad.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      }

                      await createAssetMutation.mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory')
                          localStorage.removeItem('formStep')
                          localStorage.removeItem('realestateAssetDetail')
                          localStorage.removeItem('realestateImageDetail')
                          localStorage.removeItem('assetCategory')
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        })
                    }}
                    schema={landValuationSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy it at? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = ''
                                setValue('purchase_amount', event.floatValue)
                              }}
                              className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = ''
                                setValue('current_worth', event.floatValue)
                              }}
                              className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = ''
                              setValue('purchase_date', event.target.value)
                            }}
                          />

                          {landErr && (
                            <div role="alert" aria-label={landErr} className="text-sm font-semibold text-red-500">
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}

              </>
            )}
            {category === 'VEHICLES' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      // localStorage.setItem('formStep', 'pictures')
                      console.log('form values', values)
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: vehicleAssetData.title,
                      product: vehicleAssetData.selectedProduct,
                      purpose: vehicleAssetData.purpose,
                      vehicle_sub_category: vehicleAssetData.vehicle_sub_category,
                      // vehicle_sub_category: vehicleAssetData.vehicle_sub_category ? options.find((opt) => opt.value === vehicleAssetData.vehicle_sub_category) : null,
                      brand: vehicleAssetData.brand,
                      specify_others: vehicleAssetData.specify_others,
                      tracker: vehicleAssetData.tracker,
                      model: vehicleAssetData.model,
                      mileage: vehicleAssetData.mileage,
                      parking_status: vehicleAssetData.parking_status,
                      year_of_manufacture: vehicleAssetData.year_of_manufacture,
                      description: vehicleAssetData.description,
                    }}
                    schema={vehiclesAssetDetailsSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Vehicle</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              value={vehicleAssetData.title}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(e) => {
                                formState.errors['title'] = ''
                                setValue('title', e.target.value)
                                console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                setvehicleAssetData({ ...vehicleAssetData, title: e.target.value })
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={vehicleAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data?.map((p) => ({
                                  label: p.product_title,
                                  value: p._id,
                                }))?.find((p) => p.value === e.value);
                                setValue('product', e.value)
                                formState.errors['product'] = ''
                                setvehicleAssetData({ ...vehicleAssetData, product: e.value, selectedProduct: selectedProduct })
                                storage.setAnyFormValues('vehicleAssetData',{
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Vehicle Category"
                              type="text"
                              editorContent={watch('vehicle_sub_category')}
                              name="vehicle_sub_category"
                              defaultValue={vehicleAssetData.vehicle_sub_category}
                              // defaultValue={watch('vehicle_sub_category')}
                              error={formState.errors['vehicle_sub_category']}
                              registration={register('vehicle_sub_category')}
                              options={[
                                { label: 'Private Vehicles', value: 'personal_vehicles' },
                                { label: 'Bikes', value: 'bikes' },
                                { label: 'Heavy Vehicles', value: 'heavy_vehicles' },
                                { label: 'Commercial Vehicles', value: 'commercial_vehicles' },
                              ]}
                              onChange={(e) => {
                                formState.errors['vehicle_sub_category'] = ''
                                setValue('vehicle_sub_category', e.value)
                                setvehicleAssetData({ ...vehicleAssetData, vehicle_sub_category: e.value })
                                // setVehicleSubCategory(e.value)
                              }}
                            />
                            {vehicleAssetData.vehicle_sub_category && (
                              <>
                                <ReactSelectField2
                                  label={vehicleSubCategory !== 'bikes' ? "Select Car Brand" : "Select Bike Brand"}
                                  type="text"
                                  editorContent={watch('brand')}
                                  name="brand"
                                  defaultValue={vehicleAssetData.brand}
                                  error={formState.errors['brand']}
                                  registration={register('brand')}
                                  options={vehicleSubCategory !== 'bikes' ? [
                                      { label: 'Toyota', value: 'TOYOTA' },
                                      { label: 'Nissan', value: 'NISSAN' },
                                      { label: 'Subaru', value: 'SUBARU' },
                                      { label: 'Mitsubishi', value: 'MITSUBISHI' },
                                      { label: 'Honda', value: 'HONDA' },
                                      { label: 'Mercedes Benz', value: 'MERCEDES_BENZ' },
                                      { label: 'BMW', value: 'BMW' },
                                      { label: 'Audi', value: 'AUDI' },
                                      { label: 'Volkswagen', value: 'VOLKSWAGEN' },
                                      { label: 'Peugeot', value: 'PEUGEOT' },
                                      { label: 'Suzuki', value: 'SUZUKI' },
                                      { label: 'Hyundai', value: 'HYUNDAI' },
                                      { label: 'Kia', value: 'KIA' },
                                      { label: 'Ford', value: 'FORD' },
                                      { label: 'Chevrolet', value: 'CHEVROLET' },
                                      { label: 'Jeep', value: 'JEEP' },
                                      { label: 'Range Rover', value: 'RANGE_ROVER' },
                                      { label: 'Land Rover', value: 'LAND_ROVER' },
                                      { label: 'Lexus', value: 'LEXUS' },
                                      { label: 'Mazda', value: 'MAZDA' },
                                      { label: 'Volvo', value: 'VOLVO' },
                                      { label: 'Porsche', value: 'PORSCHE' },
                                      { label: 'Jaguar', value: 'JAGUAR' },
                                      { label: 'Mini', value: 'MINI' },
                                      { label: 'Fiat', value: 'FIAT' },
                                      { label: 'Skoda', value: 'SKODA' },
                                      { label: 'Other', value: 'OTHER' },
                                    ]  :
                                    [
                                      { label: 'Bajaj', value: 'BAJAJ' },
                                      { label: 'TVS', value: 'TVS' },
                                      { label: 'Honda', value: 'HONDA' },
                                      { label: 'Yamaha', value: 'YAMAHA' },
                                      { label: 'Suzuki', value: 'SUZUKI' },
                                      { label: 'Other', value: 'OTHER' },
                                    ]}
                                  onChange={(e) => {
                                    formState.errors['brand'] = ''
                                    setValue('brand', e.value)
                                    setvehicleAssetData({ ...vehicleAssetData, brand: e.value })
                                  }}
                                />
                                {vehicleAssetData.brand === 'OTHER' && (
                                  <InputField
                                    label="Specify Other Brand"
                                    error={formState.errors['specify_others']}
                                    value={vehicleAssetData.specify_others}
                                    registration={register('specify_others')}
                                    caption="Specify a brand in case you can't find yours in the list"
                                    onChange={(e) => {
                                      formState.errors['specify_others'] = ''
                                      setValue('specify_others', e.target.value)
                                      setvehicleAssetData({ ...vehicleAssetData, specify_others: e.target.value })
                                    }}
                                  />
                                )}
                                <ReactSelectFieldWithInfo
                                label="Tracker"
                                type="text"
                                editorContent={watch('tracker')}
                                name="tracker"
                                defaultValue={vehicleAssetData.tracker}
                                error={formState.errors['tracker']}
                                registration={register('tracker')}
                    infoTitle="Vehicle Tracker"
                    infoDescription="The tracker will be used to keep track of the collateral asset's location during your loan term"
                   
                    options={[
                      { label: 'Hire', value: 'HIRE' },
                      { label: 'Buy', value: 'BUY' },
                    ]}
                    onChange={(e) => {
                      formState.errors['tracker'] = ''
                      setValue('tracker', e.value)
                      setvehicleAssetData({ ...vehicleAssetData, tracker: e.value })
                    }}
                  />
                                <ReactSelectField2
                                    label={vehicleAssetData.vehicle_sub_category !== 'bikes' ? "Select Car Model" : "Select Bike Model"}
                                    type="text"
                                    editorContent={watch('model')}
                                    name="model"
                                    defaultValue={vehicleAssetData.model}
                                    error={formState.errors['model']}
                                    registration={register('model')}
                                    options={vehicleAssetData.vehicle_sub_category !== 'bikes' ?
                                      [
                                      { label: 'Saloon', value: 'SALOON' },
                                      { label: 'SUV', value: 'SUV' },
                                      { label: 'Station Wagon', value: 'STATION_WAGON' },
                                      { label: 'Pickup', value: 'PICKUP' },
                                      { label: 'Van', value: 'VAN' },
                                      { label: 'Truck', value: 'TRUCK' },
                                      { label: 'Bus', value: 'BUS' },
                                      { label: 'Other', value: 'OTHER' },
                                    ] : [
                                      { label: 'Boda Boda', value: 'BODA_BODA' },
                                      { label: 'Sport Bike', value: 'SPORT_BIKE' },
                                      { label: 'Cruiser', value: 'CRUISER' },
                                      { label: 'Dirt Bike', value: 'DIRT_BIKE' },
                                      { label: 'Other', value: 'OTHER' },
                                    ]}
                                    onChange={(e) => {
                                      formState.errors['model'] = ''
                                      setValue('model', e.value)
                                      setvehicleAssetData({ ...vehicleAssetData, model: e.value })
                                    }}
                                  />
                              </>
                            )}
                          </div>
                          <div className="space-y-5">
                            <ReactSelectField2
                              label="Select Mileage"
                              type="text"
                              editorContent={watch('mileage')}
                              name="mileage"
                              registration={register('mileage')}
                              defaultValue={vehicleAssetData.mileage}
                              error={formState.errors['mileage']}
                              options={[
                                { label: '0 - 90000', value: '0_90000' },
                                {
                                  label: '91000 - 180000',
                                  value: '91000_180000',
                                },
                                {
                                  label: '181000 - 300000',
                                  value: '181000_300000',
                                },
                              ]}
                              onChange={(e) => {
                                formState.errors['mileage'] = ''
                                setValue('mileage', e.value)
                                setvehicleAssetData({ ...vehicleAssetData, mileage: e.value })
                              }}
                            />
                            <ReactSelectField2
                              label="Parking Status"
                              type="text"
                              editorContent={watch('parking_status')}
                              name="parking_status"
                              defaultValue={vehicleAssetData.parking_status}
                              error={formState.errors['parking_status']}
                              registration={register('parking_status')}
                              options={[
                                { label: 'Not Parked', value: 'NOT_PARKED' },
                                { label: 'Parked', value: 'PARKED' },
                              ]}
                              onChange={(e) => {
                                formState.errors['parking_status'] = ''
                                setValue('parking_status', e.value)
                                setvehicleAssetData({ ...vehicleAssetData, parking_status: e.value })
                              }}
                            />
                            <InputField
                              type="number"
                              label="Year of Manufacture"
                              error={formState.errors['year_of_manufacture']}
                              registration={register('year_of_manufacture')}
                              value={vehicleAssetData.year_of_manufacture}
                              onChange={(e) => {
                                formState.errors['year_of_manufacture'] = ''
                                setValue('year_of_manufacture', e.target.value)
                                setvehicleAssetData({ ...vehicleAssetData, year_of_manufacture: e.target.value })
                              }}
                            />
                            {/* <InputField
                            required={true}
                            label="Color"
                            error={formState.errors['color']}
                            registration={register('color')}
                          /> */}
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your vehicle. This will
                                guide us on serving you better."
                                value={vehicleAssetData.description}
                                onChange={(e) => {
                                  formState.errors['description'] = ''
                                  setValue('description', e.target.value)
                                  setvehicleAssetData({ ...vehicleAssetData, description: e.target.value })
                                }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      const bodyFormData = new FormData();
                      bodyFormData.append('color', values.color);
                      bodyFormData.append('parking_status', values.parking_status);
                      assetImages.forEach((image, index) => {
                        if (image) {
                          bodyFormData.append(`pictures`, dataURItoBlob(image));
                        }
                      });
                      await createAssetMutation.mutateAsync({ data: bodyFormData });
                      navigate('/app/assets/create/success');
                    }}
                    schema={vehiclesSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded vehicle images are clear and follow the
                          guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {[...Array(12).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                onChange={(event) => handleImageChange(index, event)}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {index === 0 && 'Front view (show number plate)'}
                                  {index === 1 && 'Side view (both sides)'}
                                  {index === 2 && 'Back view'}
                                  {index === 3 &&
                                    'Close-ups of any existing damage or unique features'}
                                  {index === 4 && "Driver's seat area"}
                                  {index === 5 && 'Passenger seating area'}
                                  {index === 6 && 'Dashboard and control panel'}
                                  {index === 7 && 'Close-ups of odometer, indicating mileage'}
                                  {index === 8 && 'Underside of vehicle'}
                                  {index === 9 && 'Close-up of Wheels and Tyres'}
                                  {index === 10 && 'Engine'}
                                  {index === 11 && 'Logbook'}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setPictures(false);
                              setValuation(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      const bodyFormData = new FormData();
                      bodyFormData.append('parking_status', values.parking_status);
                      assetImages.forEach((image, index) => {
                        if (image) {
                          bodyFormData.append(`pictures`, dataURItoBlob(image));
                        }
                      });
                      await createAssetMutation.mutateAsync({ data: bodyFormData });
                      navigate('/app/assets/create/success');
                    }}
                    schema={vehiclesSchema}>
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="mb-2 text-sm">How much did you buy the Vehicle?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={purchase_amount}
                            error={formState.errors['purchase_amount']}
                            registration={register('purchase_amount')}
                            onChange={(event) => setPurchaseAmount(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <div className="mb-2 text-sm">How much is it worth currently?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={current_worth}
                            error={formState.errors['current_worth']}
                            registration={register('current_worth')}
                            onChange={(event) => setCurrentWorth(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <InputField
                            required={true}
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                          />
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'SMART_PHONES' && (
              <Form
                id="create-post"
                onSubmit={async (values) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append('title', values.title);
                  bodyFormData.append('category', category);
                  bodyFormData.append('brand', brand);
                  bodyFormData.append('specify_others', values.specify_others);
                  bodyFormData.append('ram_size', values.ram_size);
                  bodyFormData.append('storage_space', values.storage_space);
                  bodyFormData.append('model', values.model);
                  bodyFormData.append('product', values.product);
                  bodyFormData.append('description', values.description);
                  bodyFormData.append('purchase_amount', values.purchase_amount);
                  bodyFormData.append('purchase_date', purchase_date);
                  bodyFormData.append('current_worth', current_worth);
                  assetImages.forEach((image, index) => {
                    if (image) {
                      bodyFormData.append(`pictures`, dataURItoBlob(image));
                    }
                  });
                  await createAssetMutation.mutateAsync({ data: bodyFormData });
                  navigate('/app/assets/create/success');
                }}
                schema={smartPhoneSchema}
              >
                {({ register, formState, watch, setValue }) => (
                  <>
                    {assetDetails && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Smart Phone</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              required={true}
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                            />
                            <ReactSelectField
                              required={true}
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              setValue={setValue}
                              name="product"
                              defaultValue={watch('product')}
                              error={formState.errors['product']}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Mobile Phone Brand"
                              type="text"
                              editorContent={watch('brand')}
                              setValue={setValue}
                              name="brand"
                              defaultValue={watch('brand')}
                              error={formState.errors['brand']}
                              options={phoneBrands}
                              onChange={(e) => setBrand(e.value)}
                            />
                            {brand === 'OTHER' && (
                              <InputField
                                required={true}
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                              />
                            )}
                            <SelectField
                              label="RAM Size"
                              registration={register('ram_size')}
                              options={[
                                { label: '1 - 2 GB', value: '1-2' },
                                { label: '2 - 4 GB', value: '2-4' },
                                { label: '4 - 6 GB', value: '4-6' },
                                { label: '6+ GB', value: '6+' },
                              ]}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Phone Model"
                              type="text"
                              editorContent={watch('model')}
                              setValue={setValue}
                              name="model"
                              defaultValue={watch('model')}
                              error={formState.errors['model']}
                              options={phoneModels}
                            />
                            <SelectField
                              label="Storage Space"
                              type="text"
                              editorContent={watch('storage_space')}
                              setValue={setValue}
                              name="storage_space"
                              defaultValue={watch('storage_space')}
                              registration={register('storage_space')}
                              error={formState.errors['storage_space']}
                              options={[
                                { value: '16-32', label: '16-32' },
                                { value: '32-64', label: '32-64' },
                                { value: '64-128', label: '64-128' },
                                { value: '128-256', label: '128-256' },
                              ]}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your smart phone. This will
                        guide us on serving you better."
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setAssetDetails(false);
                              setPictures(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                    {pictures && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your mobile phone are clear and
                          follow the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                onChange={(event) => handleImageChange(index, event)}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setPictures(false);
                              setValuation(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}

                    {valuation && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="mb-2 text-sm">How much did you buy the mobile phone?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={purchase_amount}
                            error={formState.errors['purchase_amount']}
                            registration={register('purchase_amount')}
                            onChange={(event) => setPurchaseAmount(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <div className="mb-2 text-sm">How much is it worth currently?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={current_worth}
                            error={formState.errors['current_worth']}
                            registration={register('current_worth')}
                            onChange={(event) => setCurrentWorth(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <InputField
                            required={true}
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                          />
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form>
            )}
            {category === 'TELEVISION' && (
              <Form
                id="create-post"
                onSubmit={async (values) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append('title', values.title);
                  bodyFormData.append('category', category);
                  bodyFormData.append('brand', brand);
                  bodyFormData.append('specify_others', values.specify_others);
                  bodyFormData.append('dimension', values.dimension);
                  bodyFormData.append('smart_tv', values.smart_tv);
                  bodyFormData.append('product', values.product);
                  bodyFormData.append('description', values.description);
                  bodyFormData.append('purchase_amount', values.purchase_amount);
                  bodyFormData.append('purchase_date', values.purchase_date);
                  bodyFormData.append('current_worth', values.current_worth);
                  assetImages.forEach((image, index) => {
                    if (image) {
                      bodyFormData.append(`pictures`, dataURItoBlob(image));
                    }
                  });
                  await createAssetMutation.mutateAsync({ data: bodyFormData });
                  navigate('/app/assets/create/success');
                }}
                schema={televisionSchema}
              >
                {({ register, formState, watch, setValue }) => (
                  <>
                    {assetDetails && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Television</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              required={true}
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                            />
                            <ReactSelectField
                              required={true}
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              setValue={setValue}
                              name="product"
                              defaultValue={watch('product')}
                              error={formState.errors['product']}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Television Brand"
                              type="text"
                              editorContent={watch('brand')}
                              setValue={setValue}
                              name="brand"
                              defaultValue={watch('brand')}
                              error={formState.errors['brand']}
                              options={[
                                { label: 'Samsung', value: 'SAMSUNG' },
                                { label: 'Apple', value: 'APPLE' },
                                { label: 'Huawei', value: 'HUAWEI' },
                                { label: 'Xiaomi', value: 'XIAOMI' },
                                { label: 'Oppo', value: 'OPPO' },
                                { label: 'Vivo', value: 'VIVO' },
                                { label: 'Tecno', value: 'TECNO' },
                                { label: 'Hisense', value: 'HISENSE' },
                                { label: 'Nokia', value: 'NOKIA' },
                                { label: 'Sony', value: 'SONY' },
                                { label: 'LG', value: 'LG' },
                                { label: 'Motorola', value: 'MOTOROLA' },
                                { label: 'OnePlus', value: 'ONEPLUS' },
                                { label: 'Google', value: 'GOOGLE' },
                                { label: 'Blackberry', value: 'BLACKBERRY' },
                                { label: 'Other', value: 'OTHER' },
                              ]}
                              onChange={(e) => setBrand(e.value)}
                            />
                            {brand === 'OTHER' && (
                              <InputField
                                required={true}
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                              />
                            )}
                            <SelectField
                              label="Dimensions"
                              registration={register('dimension')}
                              options={[
                                { label: '24', value: '24' },
                                { label: '32', value: '32' },
                                { label: '40', value: '40' },
                                { label: '43', value: '43' },
                                { label: '50', value: '50' },
                                { label: '55', value: '55' },
                                { label: '65', value: '65' },
                                { label: '75', value: '75' },
                                { label: '85', value: '85' },
                                { label: '100', value: '100' },
                              ]}
                            />
                            <SelectField
                              label="Smart TV?"
                              registration={register('smart_tv')}
                              options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                              ]}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your television. This will
                        guide us on serving you better."
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setAssetDetails(false);
                              setPictures(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                    {pictures && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your television are clear and follow
                          the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                onChange={(event) => handleImageChange(index, event)}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setPictures(false);
                              setValuation(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}

                    {valuation && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <InputField
                            required={true}
                            label="How much did you buy the television?"
                            error={formState.errors['purchase_amount']}
                            registration={register('purchase_amount')}
                          />
                          <InputField
                            required={true}
                            label="How much is it worth currently?"
                            error={formState.errors['current_worth']}
                            registration={register('current_worth')}
                          />
                          <InputField
                            required={true}
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                          />
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form>
            )}
            {category === 'COMPUTERS' && (
              <Form
                id="create-post"
                onSubmit={async (values) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append('title', values.title);
                  bodyFormData.append('category', category);
                  bodyFormData.append('brand', brand);
                  bodyFormData.append('specify_others', values.specify_others);
                  bodyFormData.append('ram_size', values.ram_size);
                  bodyFormData.append('hard_disk_space', values.hard_disk_space);
                  bodyFormData.append('processor_speed', values.processor_speed);
                  bodyFormData.append('product', values.product);
                  bodyFormData.append('description', values.description);
                  bodyFormData.append('purchase_amount', purchase_amount);
                  bodyFormData.append('purchase_date', values.purchase_date);
                  bodyFormData.append('current_worth', current_worth);
                  assetImages.forEach((image, index) => {
                    if (image) {
                      bodyFormData.append(`pictures`, dataURItoBlob(image));
                    }
                  });
                  await createAssetMutation.mutateAsync({ data: bodyFormData });
                  navigate('/app/assets/create/success');
                }}
                schema={computersSchema}
              >
                {({ register, formState, watch, setValue }) => (
                  <>
                    {assetDetails && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Computer</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              required={true}
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                            />
                            <ReactSelectField
                              required={true}
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              setValue={setValue}
                              name="product"
                              defaultValue={watch('product')}
                              error={formState.errors['product']}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Computer Brand"
                              type="text"
                              editorContent={watch('brand')}
                              setValue={setValue}
                              name="brand"
                              defaultValue={watch('brand')}
                              error={formState.errors['brand']}
                              options={[
                                { label: 'Apple', value: 'APPLE' },
                                { label: 'Dell', value: 'DELL' },
                                { label: 'HP', value: 'HP' },
                                { label: 'Lenovo', value: 'LENOVO' },
                                { label: 'Acer', value: 'ACER' },
                                { label: 'Asus', value: 'ASUS' },
                                { label: 'Microsoft', value: 'MICROSOFT' },
                                { label: 'Toshiba', value: 'TOSHIBA' },
                                { label: 'Samsung', value: 'SAMSUNG' },
                                { label: 'LG', value: 'LG' },
                                { label: 'Sony', value: 'SONY' },
                                { label: 'Other', value: 'OTHER' },
                              ]}
                              onChange={(e) => setBrand(e.value)}
                            />
                            {brand === 'OTHER' && (
                              <InputField
                                required={true}
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                              />
                            )}
                            <SelectField
                              label="RAM Size"
                              registration={register('ram_size')}
                              options={[
                                { value: '', label: 'Select..' },
                                { label: '1 - 2 GB', value: '1-2' },
                                { label: '2 - 4 GB', value: '2-4' },
                                { label: '4 - 6 GB', value: '4-6' },
                                { label: '6+ GB', value: '6+' },
                              ]}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Processor Speed"
                              type="text"
                              editorContent={watch('processor_speed')}
                              setValue={setValue}
                              name="processor_speed"
                              defaultValue={watch('processor_speed')}
                              error={formState.errors['processor_speed']}
                              options={[
                                { value: '', label: 'Select..' },
                                { value: '1-2', label: '1-2' },
                                { value: '2-3', label: '2-3' },
                                { value: '3+', label: '3+' },
                              ]}
                            />
                            <SelectField
                              label="Hard Disk Space"
                              type="text"
                              editorContent={watch('hard_disk_space')}
                              setValue={setValue}
                              name="hard_disk_space"
                              registration={register('hard_disk_space')}
                              error={formState.errors['hard_disk_space']}
                              options={[
                                { value: '', label: 'Select..' },
                                { value: '100-250', label: '100-250' },
                                { value: '260-500', label: '260-500' },
                                { value: '500-1TB', label: '500-1TB' },
                                { value: '1TB+', label: '1TB+' },
                              ]}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your computer. This will
                        guide us on serving you better."
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setAssetDetails(false);
                              setPictures(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                    {pictures && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your computer are clear and follow
                          the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                onChange={(event) => handleImageChange(index, event)}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setPictures(false);
                              setValuation(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}

                    {valuation && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="mb-2 text-sm">How much did you buy the computer?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={purchase_amount}
                            error={formState.errors['purchase_amount']}
                            registration={register('purchase_amount')}
                            onChange={(event) => setPurchaseAmount(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <div className="mb-2 text-sm">How much is it worth currently?</div>
                          <ReactNumberFormatInputField2
                            prefix="UGX "
                            value={current_worth}
                            error={formState.errors['current_worth']}
                            registration={register('current_worth')}
                            onChange={(event) => setCurrentWorth(event.floatValue)}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                          <InputField
                            required={true}
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                          />
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form>
            )}
            {category === 'OTHER_ELECTRONICS' && (
              <Form
                id="create-post"
                onSubmit={async (values) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append('title', values.title);
                  bodyFormData.append('category', category);
                  values.specify_others
                    ? bodyFormData.append('specify_others', values.specify_others)
                    : null;
                  bodyFormData.append('nature_of_electronic', nature_of_electronic);
                  bodyFormData.append('product', values.product);
                  bodyFormData.append('description', values.description);
                  bodyFormData.append('model_type', values.model_type);
                  bodyFormData.append('purchase_amount', values.purchase_amount);
                  bodyFormData.append('purchase_date', values.purchase_date);
                  bodyFormData.append('current_worth', values.current_worth);
                  assetImages.forEach((image, index) => {
                    if (image) {
                      bodyFormData.append(`pictures`, dataURItoBlob(image));
                    }
                  });
                  await createAssetMutation.mutateAsync({ data: bodyFormData });
                  navigate('/app/assets/create/success');
                }}
                schema={otherElectronicsSchema}
              >
                {({ register, formState, watch, setValue }) => (
                  <>
                    {assetDetails && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for other electronics</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              required={true}
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                            />
                            <ReactSelectField
                              required={true}
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              setValue={setValue}
                              name="product"
                              defaultValue={watch('product')}
                              error={formState.errors['product']}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                            />
                            <ReactSelectField
                              required={true}
                              label="Select Electronic"
                              type="text"
                              editorContent={watch('nature_of_electronic')}
                              setValue={setValue}
                              name="nature_of_electronic"
                              defaultValue={watch('nature_of_electronic')}
                              error={formState.errors['nature_of_electronic']}
                              options={[
                                { label: 'Refrigerator', value: 'REFRIGERATOR' },
                                { label: 'Washing Machine', value: 'WASHING_MACHINE' },
                                { label: 'Gaming Console', value: 'GAMING_CONSOLE' },
                                { label: 'Cooker', value: 'COOKER' },
                                { label: 'Oven', value: 'OVEN' },
                                { label: 'Microwave', value: 'MICROWAVE' },
                                { label: 'Others', value: 'OTHERS' },
                              ]}
                              onChange={(e) => setNatureOfElectronic(e.value)}
                            />
                            {nature_of_electronic === 'OTHERS' && (
                              <InputField
                                required={true}
                                label="Specify Other"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify an electronic in case you can't find yours in the list"
                              />
                            )}
                            <InputField
                              required={true}
                              label="Model Type"
                              error={formState.errors['model_type']}
                              registration={register('model_type')}
                              caption="Specify a model of the electronic"
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your computer. This will
                        guide us on serving you better."
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'You will lose all your progress!',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, close it!',
                                cancelButtonText: 'No, keep it',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  setCategory('');
                                  setAssetDetails(false);
                                }
                              });
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setAssetDetails(false);
                              setPictures(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}
                    {pictures && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your electronic are clear and follow
                          the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                onChange={(event) => handleImageChange(index, event)}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => {
                              setPictures(false);
                              if (assetImages && assetImages.length > 4) {
                                setValuation(true);
                              } else {
                                Swal.fire({
                                  title: 'Unable to proceed',
                                  text: 'Please upload at least 5 images to proceed, as they are crucial for our due diligence process. Thank you.',
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, close it!',
                                  cancelButtonText: 'No, keep it',
                                });
                              }
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    )}

                    {valuation && (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <InputField
                            required={true}
                            label="How much did you buy the electronic?"
                            error={formState.errors['purchase_amount']}
                            registration={register('purchase_amount')}
                          />
                          <InputField
                            required={true}
                            label="How much is it worth currently?"
                            error={formState.errors['current_worth']}
                            registration={register('current_worth')}
                          />
                          <InputField
                            required={true}
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                          />
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
