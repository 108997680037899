import { Spinner } from 'components/Elements';
import { useTips } from 'features/pigeonLoans';
import React from 'react';
import { TypeAnimation } from 'react-type-animation';

export const LoanTips = () => {
  const tipsQuery = useTips();

  if (tipsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!tipsQuery.data?.length > 0) return null;
  return (
    <div>
      <div className="mb-4 font-bold text-lg">Loan tips</div>
      <div className="bg-white rounded-2xl shadow-lg p-12">
        <TypeAnimation
          sequence={
            tipsQuery.data?.map((tip) => {
              return {
                content: tip,
                duration: 4000,
              };
            }) || []
          }
          wrapper="span"
          speed={10}
          repeat={Infinity}
          cursor={false}
          aria-expanded={false}
          deletionSpeed={100}
        />
      </div>
    </div>
  );
};
