import React, { useRef, useState } from 'react';
import * as z from 'zod';
import { Button } from 'components/Elements';
import { Form } from 'components/Form';
import { useCreateLoan } from '../../api/createLoan';
import SignatureCanvas from 'react-signature-canvas';
import storage from 'utils/storage';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const schema = z.object({
  videoDates0: z.array(z.string()),
  videoDates1: z.array(z.string().optional()).optional(),
});

export const Signature = ({ prevStep }) => {
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);

  const navigate = useNavigate();
  const createLoanMutation = useCreateLoan();

  const [videoDates, setVideoDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([{ hours: 9, minutes: 0, meridiem: 'AM' }]);
  const [finish, setFinish] = useState(false);
  const [signErr, setSignErr] = useState()
  const [isSigned, setisSigned] = useState(false)

  const clear = () => {
    sigPadRef.current.clear();
    setisSigned(false)
    setSignErr()
  };

  const trim = () => {
    try {
      if (isSigned) {
        const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
        if (trimmedData) {
          // console.log('trimmedData', trimmedData)
          setSignErr()
          setTrimmedDataURL(trimmedData);
          setFinish(true);
        } else {
          setSignErr('Something went wrong, try again')
        }
      } else {
        setSignErr('Please sign to continue')
      }

    } catch (error) {
      setSignErr('Something went wrong, try again')
    }
  };

  const handleAddVideoDate = () => {
    if (videoDates.length < 2) {
      setVideoDates([...videoDates, '']);
      setTimeSlots([...timeSlots, { hours: 9, minutes: 0, meridiem: 'AM' }]);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'You can only select 2 dates for the introductory video call',
      });
    }
  };
  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]);
  };

  const values = storage.getFormValues();

  // console.log({ values });

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots[index][field] = value;
    setTimeSlots(updatedTimeSlots);
  };

  return (
    <div className="mx-12">
      <Form
        id="create-loan"
        onSubmit={async () => {
          setSignErr()
          // console.log('inside submit signature')
          
          if (!trimmedDataURL) {
            setSignErr('Signature is required')
            return
          }
          storage.setFormValues({
            signature: trimmedDataURL,
          });
          if (!videoDates) {
            setSignErr('Set date and time to proceed')
            return
          }
          if (!timeSlots) {
            setSignErr('Set time to proceed')
            return
          }
          const vls = JSON.parse(localStorage.getItem(`msw-dbformValues`))
          const dt = {
            ...vls,
            introductory_video_call_scheduled_dates: videoDates.map((date, index) => ({
              date,
              time_slots: timeSlots[index],
            })),
          }
          console.log('vls', dt)
          await createLoanMutation.mutateAsync({
            data: {
              ...vls,
              introductory_video_call_scheduled_dates: videoDates.map((date, index) => ({
                date,
                time_slots: timeSlots[index],
              })),
            },
          })
          .then((data) => {
            localStorage.removeItem('guarantorData')
            localStorage.removeItem('paymentMethod')
            localStorage.removeItem('acceptTerms')
            localStorage.removeItem('vehicleType')
            localStorage.removeItem('trackerType')
            localStorage.removeItem('selectedProduct')
            localStorage.removeItem('msw-dbformValues')
        localStorage.removeItem('pigeonLoansCurrentStep')
            navigate('/app/pigeon-loans/success');
          })
          .catch((err) => {
            // console.log('login error', err);
            setSignErr(`${err.message}. Try again.`);
          })
          
        }}
        schema={schema}
      >
        {({ formState, register, setValue }) => (
          <div className="">
            {trimmedDataURL ? (
              <>
                {' '}
                <div className="text-lg font-semibold">
                  Select Dates for an introductory Video Call
                </div>
                <div className="">
                  Please select at least 2 dates and set time for your availability and the assigned
                  loan officer will confirm one and also send you a meeting link.{' '}
                </div>
              </>
            ) : (
              <>
                <div className="text-lg font-semibold mb-6">Digital Signature Required</div>
                <div className="mb-4">
                  Please use the canvas below to capture your digital signature. This signature will
                  be utilized on loan contracts.
                </div>
              </>
            )}

            <div className="mb-4 p-4">
              {trimmedDataURL ? (
                <div className="flex flex-col space-y-2">
                  <div className="bg-white shadow-md mb-4 rounded-2xl p-4">
                    <div className="flex flex-row justify-between space-x-8 items-center mb-4 rounded-2xl px-5 bg-gray-100 p-2">
                      <div className="">Set availability</div>
                      <div className="flex flex-row items-center space-x-2">
                        <Button size="sm" variant="outline" onClick={handleAddVideoDate}>
                          New Date
                        </Button>
                      </div>
                    </div>
                    {videoDates.map((date, index) => (
                      <div
                        className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2 mb-3"
                        key={index}>
                        <input
                          type="date"
                          error={formState.errors[`videoDates${index}`]}
                          registration={register(`videoDates${index}`)}
                          onChange={(e) => {
                            formState.errors[`videoDates${index}`] = ''
                            const updatedDates = [...videoDates];
                            updatedDates[index] = e.target.value;
                            setVideoDates(updatedDates);
                            setValue(`videoDates${index}`, updatedDates)
                          }}
                          className="border border-gray-200 rounded-md shadow-sm p-2 w-full mb-2"
                        />
                        {formState.errors[`videoDates${index}`]?.message && (
                                    <div role="alert" aria-label={formState.errors[`videoDates${index}`].message} className="text-sm font-semibold text-red-500">
                                      {formState.errors[`videoDates${index}`].message}
                                    </div>
                                  )}
                        {timeSlots[index] ? (
                          <div className="flex flex-row items-center space-x-2">
                            <select
                              value={timeSlots[index].hours}
                              onChange={(e) => handleTimeSlotChange(index, 'hours', e.target.value)}
                              className="border border-gray-200 rounded-md shadow-sm p-2"
                            >
                              {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                            <span>:</span>
                            <select
                              value={timeSlots[index].minutes}
                              onChange={(e) =>
                                handleTimeSlotChange(index, 'minutes', e.target.value)
                              }
                              className="border border-gray-200 rounded-md shadow-sm p-2"
                            >
                              {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute < 10 ? `0${minute}` : minute}
                                </option>
                              ))}
                            </select>
                            <select
                              value={timeSlots[index].meridiem}
                              onChange={(e) =>
                                handleTimeSlotChange(index, 'meridiem', e.target.value)
                              }
                              className="border border-gray-200 rounded-md shadow-sm p-2"
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        ) : (
                          <Button size="sm" variant="outline" onClick={handleAddTimeSlot}>
                            Set Available Time
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="overflow-hidden mx-auto flex flex-row items-center justify-center">
                  <SignatureCanvas
                    penColor="darkblue"
                    onEnd={() => setisSigned(true)}
                    canvasProps={{
                      width: 400,
                      height: 200,
                      className:
                        'sigCanvas border border-gray-200 rounded-2xl shadow bg-white mb-4',
                    }}
                    ref={sigPadRef}
                  />
                </div>
              )}
              {signErr && (
                <div role="alert" aria-label={signErr} className="text-sm font-semibold text-red-500">
                  {signErr}
                </div>
              )}
              {!trimmedDataURL && (
                <div className="flex flex-row justify-center items-center space-x-2">
                  <Button size="sm" type="button" variant="outline" onClick={clear}>
                    Clear
                  </Button>
                  <Button size="sm" type="button" onClick={trim}>
                    Confirm Signature
                  </Button>
                </div>
              )}
            </div>
            {finish && (
              <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                <div className="flex flex-row items-center space-x-2">
                  <Button variant="outline" onClick={() => prevStep()} className="w-full">
                    Previous
                  </Button>
                  <Button
                    isLoading={createLoanMutation.isLoading}
                    variant="dark"
                    type="submit"
                    className="w-full"
                  >
                    Finish
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};
