import React from 'react';
import { useParams } from 'react-router-dom';
import { getLateFeePercentage } from 'utils/getLateFeePercentage';
import { useContract } from '../api/getContract';

export const LateFeePercentage = () => {
  const { id } = useParams();

  const contractQuery = useContract({ id });
  if (contractQuery.isLoading) {
    return <></>;
  }

  if (!contractQuery.data) return null;
  const late_fee_percentage = getLateFeePercentage(
    contractQuery.data?.loanAmount,
    contractQuery.data?.product?.late_fees
  );
  return <span className="font-bold">{late_fee_percentage || 0.25}% per day</span>;
};
