export function getLateFeePercentage(amount, lateFees) {
  if (!Array.isArray(lateFees)) {
    // Handle the case where lateFees is not an array
    console.error('lateFees is not an iterable array:', lateFees);
    return null;
  }

  // Check if amount is a valid number
  if (typeof amount !== 'number' || isNaN(amount)) {
    console.error('Invalid amount:', amount);
    return null;
  }

  for (let fee of lateFees) {
    // Ensure fee.range and its bounds are defined
    if (fee.range && typeof fee.range.min === 'number' && typeof fee.range.max === 'number') {
      // Check if the amount is within the range
      if (amount >= fee.range.min && amount <= fee.range.max) {
        return fee.percentage;
      }
    } else {
      console.warn('Invalid fee range:', fee.range);
    }
  }

  // If no valid percentage is found, return null
  return null;
}
