export function getServiceFeePercentage(amount, serviceFees) {
  // Check for undefined or invalid inputs
  if (typeof amount === 'undefined' || amount === null || isNaN(amount)) {
    return null;
  }

  if (!Array.isArray(serviceFees)) {
    return null;
  }

  // Convert amount to integer
  const parsedAmount = parseInt(amount, 10);

  for (let fee of serviceFees) {
    // Check if fee.range is valid
    if (fee.range && fee.range.min !== undefined && fee.range.max !== undefined) {
      // Convert fee.range values to integers
      const parsedMin = parseInt(fee.range.min, 10);
      const parsedMax = parseInt(fee.range.max, 10);

      // Check if the amount is within the range
      if (parsedAmount >= parsedMin && parsedAmount <= parsedMax) {
        return fee.percentage;
      }
    }
  }

  return null;
}
