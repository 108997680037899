import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { PigeonTips } from '../components/PigeonTips';
import { SelectPaymentMethod } from '../components/steps/SelectPaymentMethod';
import { LoanDetails } from '../components/steps/LoanDetails';
import { Guarantor } from '../components/steps/Guarantor';
import { Collateral } from '../components/steps/Collateral';
import { TermsAndConditions } from '../components/steps/TermsAndConditions';
import { Signature } from '../components/steps/Signature';
import { useAuth } from 'lib/auth';
import { DocumentUploadField, Form, UploadField } from 'components/Form';
import { useUploadFiles } from '../api/uploadFiles';
import { Button } from 'components/Elements';

const schema = z.object({
  avatar: z.string({
    required_error: "Selfie is required",
    invalid_type_error: "Selfie must be a string",
  }),
})
export const PigeonLoans = () => {
  const [step, setStep] = useState(1);
  const [avatar, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [nationalIDBack, setNationalIDBack] = useState();
  const [nationalIDFront, setNationalIDFront] = useState();
  const [canProceed, setCanProceed] = useState(false);
  const [img1err, setimg1err] = useState('');
  const [img2err, setimg2err] = useState('');
  const [img3err, setimg3err] = useState('');

  const { user } = useAuth();

  const uploadFilesMutation = useUploadFiles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if all required details for the current step are filled to enable proceeding to the next step
    const getData = async() => {
    //   const selfieImage = localStorage.getItem('pigeonLoansSelfie');
    //   const nationalIDFront = localStorage.getItem('pigeonLoansNationalIDFront');
    //   const nationalIDBack = localStorage.getItem('pigeonLoansNationalIDBack');
    // localStorage.setItem('pigeonLoansCurrentStep', 4)
       const currentLoanStep = localStorage.getItem('pigeonLoansCurrentStep');

    // if (selfieImage) {
    //   setImage(selfieImage);
    //   setPreview(selfieImage);
    // }
    // if (nationalIDFront) {
    //   setNationalIDFront(nationalIDFront);
    // }
    // if (nationalIDBack) {
    //   setNationalIDBack(nationalIDBack);
    // }
    if (currentLoanStep) {
      console.log('current loan step', currentLoanStep)
      if (currentLoanStep !== '1') {
        setStep(parseInt(currentLoanStep));
      }
    }
    }
    getData()
    switch (step) {
      case 1:
        setCanProceed(!!avatar && !!nationalIDFront && !!nationalIDBack); // Example condition for step 1
        break;
      // Add conditions for other steps as needed
      default:
        setCanProceed(true);
    }
  }, [step, avatar, nationalIDFront, nationalIDBack]);

  const goToStep = (stepNumber) => {
    if (stepNumber < step || canProceed) {
      setStep(stepNumber);
    }
  };

  const steps = [
    'Payment Method',
    'Loan Details',
    'Guarantor',
    'Collateral Assets',
    'Terms and Conditions',
    'Signature & Video Call',
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <PigeonTips />
      <div className="md:col-span-2 py-12">
        {!user.basicInformation?.avatar || !user.documentVerification?.nationalID?.front ? (
          <div className="m-8">
            <div className="text-lg mb-3">Please submit the following required items;</div>
            <Form
              id="update-files"
              onSubmit={async () => {
                if (!avatar) {
                  setimg1err('Selfie Photo cannot be empty')
                  return
                }
                if (!nationalIDFront) {
                  setimg2err('NationalID Front Photo cannot be empty')
                  return
                }
                if (!nationalIDBack) {
                  setimg3err('NationalID Back Photo cannot be empty')
                  return
                }
                setimg1err('')
                setNationalIDBack('')
                setNationalIDFront('')
                const bodyFormData = new FormData();
                bodyFormData.append('avatar', avatar);
                bodyFormData.append('nationalIDFront', nationalIDFront);
                bodyFormData.append('nationalIDBack', nationalIDBack);
                await uploadFilesMutation.mutateAsync({ data: bodyFormData, id: user._id });
                localStorage.setItem('pigeonLoansCurrentStep', '1');
                setPreview(null);
                setShow(false); // Assuming setShow is defined elsewhere or needs to be implemented
              }}
              options={{
                defaultValues: {
                  avatar: user.basicInformation?.avatar,
                },
              }}
              schema={schema}
            >
              {({ register, formState, setValue }) => (
                <>
                  <UploadField
                    title="Provide a Selfie Photo"
                    // error={img1err}
                    error={formState.errors['avatar']}
                    registration={register('avatar')}
                    caption="A selfie helps improve your credit rating and let's lenders recognize you."
                    onChange={(e) => {
                      setValue('avatar', 'file set');
                      const file = e.target.files[0];
                      if (file && file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          // setImage(base64String);
                          localStorage.setItem('pigeonLoansSelfie', base64String);
                        };
                        reader.readAsDataURL(file);
                        setImage(file);
                      setPreview(URL.createObjectURL(file));
                      } else {
                        setimg1err('Please upload a valid selfie image')
                      }
                    }}
                    button={
                      <button
                        type="button"
                        onClick={() => {
                          setPreview(null);
                        }}
                        className="text-red-500 cursor-pointer"
                      >
                        Remove
                      </button>
                    }
                    preview={preview}
                  />
                  <div className="">
                    <div className="">
                      <span className="font-semibold text-lg">Identification Document</span>
                      <br />
                      <span className="text-md">National ID / Passport</span>
                      <br />
                      <span>
                        Please provide clear images so that your loan request may be accepted.
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <DocumentUploadField
                      title="Front"
                      error={img2err}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.startsWith('image/')) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64String = reader.result;
                            // setImage(base64String);
                            localStorage.setItem('pigeonLoansNationalIDFront', base64String);
                          };
                          reader.readAsDataURL(file);
                          setNationalIDFront(file);
                        } else {
                          setimg1err('Please upload a valid front National Id image')
                        }
                      }}
                    />
                    <DocumentUploadField
                      title="Back"
                      required
                      error={img3err}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.startsWith('image/')) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64String = reader.result;
                            // setImage(base64String);
                            localStorage.setItem('pigeonLoansNationalIDBack', base64String);
                          };
                          reader.readAsDataURL(file);
                          setNationalIDBack(file);
                        } else {
                          setimg3err('Please upload a valid back National Id image')
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-end items-center space-x-2">
                    <Button isLoading={uploadFilesMutation.isLoading} type="submit">
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        ) : (
          <>
            <div className="flex justify-center space-x-4 mb-4">
              {steps.map((label, index) => (
                <div key={index} className="flex flex-col items-center space-y-2">
                  <button
                    type="button"
                    className={`flex items-center justify-center h-8 w-8 rounded-full ${
                      step === index + 1 ? 'bg-primary-100 text-white' : 'bg-gray-300 text-gray-500'
                    }`}
                    onClick={() => {
                      const nextStep = index + 1
                      localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                      setStep(nextStep)
                    }}
                    disabled={!canProceed && index + 1 > step}
                  >
                    {index + 1}
                  </button>
                  <div className="whitespace-nowrap text-xs hidden md:block">{label}</div>
                </div>
              ))}
            </div>
            {step === 1 && <SelectPaymentMethod nextStep={() => {
              const nextStep = step + 1
              localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
              setStep(nextStep)
            }} />}
            {step === 2 && (
              <LoanDetails nextStep={() => {
                const nextStep = step + 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} prevStep={() => {
                const nextStep = step - 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} />
            )}
            {step === 3 && (
              <Guarantor nextStep={() => {
                const nextStep = step + 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} prevStep={() => {
                const nextStep = step - 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} />
            )}

            {step === 4 && (
              <Collateral nextStep={() => {
                const nextStep = step + 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} prevStep={() => {
                const nextStep = step - 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} />
            )}
            {step === 5 && (
              <TermsAndConditions
              nextStep={() => {
                const nextStep = step + 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} prevStep={() => {
                const nextStep = step - 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }}
              />
            )}
            {step === 6 && <Signature prevStep={() => {
                const nextStep = step - 1
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep)
              }} />}
          </>
        )}
      </div>
    </div>
  );
};
