import React from 'react';
import PropTypes from 'prop-types';
import 'tailwindcss/tailwind.css';
import { useMyLoan } from 'features/lendMoney';

export const LoanProgress = ({ loanProgress }) => {
  const myloanQuery = useMyLoan();

  const steps = [
    'Loan application submitted successfully',
    'Initial consultation video call',
    'Preliminary Approval',
    'Due Diligence Review',
    'Credit Committee Review',
    'Loan Offer',
    'Agreement Drafted',
    "Currently waiting for borrower's signature",
    `Currently waiting for lender's signature`,
    'Waiting for contract approval from Finablr admin',
    'Waiting to release funds and initiate contract',
  ];

  const stepPercentage = (index) => (index / (steps.length - 1)) * 100;

  if (myloanQuery.data?.loanProgress === 11) {
    return null;
  }

  return (
    <>
      <div className="font-bold text-lg mb-3">Check out your loan progress.</div>
      <div className="w-full bg-white shadow p-3 rounded-2xl mb-3">
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div className="flex-1">
              <span
                style={{
                  fontSize: '0.75rem',
                }}
                className="font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-primary-100 whitespace-nowrap"
              >
                {steps[loanProgress]}
              </span>
            </div>
            <div className="text-right">
              <span className="text-xs font-semibold inline-block text-primary-100">
                {stepPercentage(loanProgress).toFixed(0)}%
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-100">
            <div
              style={{ width: `${stepPercentage(loanProgress)}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-100 transition-all duration-500 ease-out"
            ></div>
          </div>
        </div>
        <div
          className="hidden sm:flex justify-between text-gray-600"
          style={{
            fontSize: '0.7rem',
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex-1 text-center ${
                index <= loanProgress ? 'text-primary-100 font-semibold' : ''
              }`}
            >
              {/* {step} */}
            </div>
          ))}
        </div>
        <div className="flex sm:hidden flex-col space-y-2 text-xs text-gray-600">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`text-left ${
                index <= loanProgress ? 'text-primary-100 font-semibold' : ''
              }`}
            >
              {index + 1}. {step}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

LoanProgress.propTypes = {
  loanProgress: PropTypes.number.isRequired,
};
